// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

import { generatePath } from 'react-router-dom';
import { z } from "zod";
import { ValidationError } from "../../library/classes/exceptions";
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ITEM_CREATE = '/project/:projectId/item';
const ITEM_SINGLE = '/item/:itemId';
const ITEM_SINGLE_ACTION = '/item/:itemId/:action';
const ITEM_SINGLE_ACTION_SINGLE = '/item/:itemId/:action/:id/:secondaryAction';
const CUSTOM_SPECIFICATION_SINGLE = '/item/custom-specification/:specId';
const HIDDEN_SPECIFICATION_SINGLE = '/item/:itemId/hidden-specification/:specId';
const ITEM_SINGLE_ACTION_ID = '/item/:action/:id/:secondaryAction';
const ITEM_SEARCH = '/item/search';

// const AdditionalQuoatation = z.object({
//     reason: z.string({ required_error: 'Reason is Required' }).email(),
//     price: z.number({ required_error: 'Price is Required' }),
// })

const AdditionalQuotationSchema = z.object({
    reason: z.number({ required_error: 'Reason is required' }),
    description: z.string().optional(),
    price: z.number({ required_error: 'Purchase price is required', invalid_type_error: 'Invalid value for price' }).positive({ message: 'Price must be greater than 0' }),
    margin: z.number({ required_error: 'Margin is required' }),
    name: z.string({ required_error: 'Name is required' }),
    sales_price: z.number({ required_error: 'Sale price is required', invalid_type_error: 'Invalid value for price' }).positive({ message: 'Sale price must be greater than 0' }),
    item_notification_id: z.number().optional(),
})

export const createProjectItem = (projectId, payload) => {
    return axiosInstance.post(generatePath(ITEM_CREATE, {
        projectId
    }), payload)
}

export const getProjectItem = (itemId) => {
    return axiosInstance.get(generatePath(ITEM_SINGLE, {
        itemId
    })).then(({ data }) => data)
}

export const deleteItem = (itemId) => {
    return axiosInstance.delete(generatePath(ITEM_SINGLE, {
        itemId
    })).then(({ data }) => data)
}

export const updateProjectItem = (itemId, payload) => {
    return axiosInstance.patch(generatePath(ITEM_SINGLE, {
        itemId
    }), payload)
}

export const assignFinisher = (itemId, finisherId, addressId) => {
    return axiosInstance.post(generatePath(ITEM_SINGLE_ACTION_SINGLE, {
        itemId,
        action: 'finisher',
        id: finisherId,
        secondaryAction: 'assign'
    }), { addressId }).then(({ message }) => message)
}

export const unassignFinisher = (itemId, finisherId) => {
    return axiosInstance.delete(generatePath(ITEM_SINGLE_ACTION_SINGLE, {
        itemId,
        action: 'finisher',
        id: finisherId,
        secondaryAction: 'assign'
    })).then(({ message }) => message)
}

export const uploadItemArtwork = (itemId, payload) => {
    return axiosInstance.post(generatePath(ITEM_SINGLE_ACTION, {
        itemId,
        action: 'upload-artwork'
    }), payload)

}
export const deleteItemArtwork = (itemId, url) => {
    return axiosInstance.delete(generatePath(ITEM_SINGLE_ACTION, {
        itemId,
        action: 'upload-artwork',
    }), {
        params: { url }
    }).then(({ message }) => message)
}

// export const postAddtionalQuotation = (itemId, payload) => {
//     return axiosInstance.post(generatePath(ITEM_SINGLE_ACTION, {
//         itemId,
//         action: 'additional-quotation'
//     }), payload).then(({ data }) => data)

// }
export const postAddtionalQuotation = (itemId, additional_quotation) => {
    //Get parsed data
    const parsedData = additional_quotation?.map(AdditionalQuotationSchema.safeParse)

    //Format errors so each data line errors can be handled separately
    const errors = parsedData?.map(data => {
        const { success, error } = data
        if (success) {
            return false
        }
        return error.flatten()?.fieldErrors
    }).reduce((acc, error, index) => {
        if (error) {
            acc[index] = error
        }
        return acc
    }, {})

    //Check if there are any errors
    if (errors && Object.values(errors).length > 0) {
        throw new ValidationError('Unable to submit quotation.Please check all the fields', errors, false)
    }

    //If there is no error, then all data is parsed successfully
    //Remove sales_price
    const payload = parsedData.map(({ data }) => {
        delete data.sales_price
        return data
    })
    console.log({ payload })
    return axiosInstance.post(generatePath(ITEM_SINGLE_ACTION, {
        itemId,
        action: 'additional-quotation'
    }), { additional_quotation: payload }).then(({ data }) => data)
}

export const sendAddtionalQuotation = (quotationID) => {
    return axiosInstance.post(generatePath(ITEM_SINGLE_ACTION_ID, {
        action: 'additional-quotation',
        id: quotationID,
        secondaryAction: 'send'
    }), {})
}
export const resendAddtionalQuotation = (quotationID) => {
    return axiosInstance.post(generatePath(ITEM_SINGLE_ACTION_ID, {
        action: 'additional-quotation',
        id: quotationID,
        secondaryAction: 'resend'
    }), {})
}
export const cancelAddtionalQuotation = (quotationID) => {
    return axiosInstance.post(generatePath(ITEM_SINGLE_ACTION_ID, {
        action: 'additional-quotation',
        id: quotationID,
        secondaryAction: 'cancel'
    }), {})

}



export const getAdditonalQuoataion = (itemId) => {
    return axiosInstance.get(generatePath(ITEM_SINGLE_ACTION, {
        itemId,
        action: 'additional-quotation'
    })).then(({ data }) => data)

}
export const copyItemWithinProject = (itemId, payload) => {
    return axiosInstance.post(generatePath(ITEM_SINGLE_ACTION, {
        itemId,
        action: 'copy-item-within-project',
    }), payload).then(({ message }) => message)

}


export const updateProjectItemSpecifications = (itemId, payload) => {
    return axiosInstance.post(generatePath(ITEM_SINGLE_ACTION, {
        itemId,
        action: 'specification'
    }), payload).then(({ data }) => data)

}

export async function searchProject(query = '', vendorId = null) {
    const params = { q: query }
    if (vendorId) {
        params.vendor_id = vendorId
    }
    return axiosInstance.get('/project/search', { params })
        .then(({ data }) =>
            data.map(({ id, company }) => ({
                value: id,
                label: `#${id} : ${company}`,
            })),
        )
}

export async function searchProjectAdvance(query = '', filters) {
    const params = { q: query, ...filters }
    return axiosInstance.get('/project/search-project', { params })
        .then(({ data }) =>
            data.map(({ id, company }) => ({
                value: id,
                label: `#${id} : ${company}`,
            })),
        )
}

export async function searchProjectItems(query, filters) {
    const params = { q: query, ...filters }
    return axiosInstance.get(ITEM_SEARCH, { params })
        .then(({ data }) => data)
}

export const addItemCustomSpecification = (itemId, payload) => {
    return axiosInstance.post(generatePath(ITEM_SINGLE_ACTION, {
        itemId,
        action: 'custom-specification'
    }), payload).then(({ message }) => message)
}

export const updateItemCustomSpecification = (specId, payload) => {
    return axiosInstance.patch(generatePath(CUSTOM_SPECIFICATION_SINGLE, {
        specId,
    }), payload).then(({ message }) => message)
}

export const deleteItemCustomSpecification = (specId) => {
    return axiosInstance.delete(generatePath(CUSTOM_SPECIFICATION_SINGLE, {
        specId,
    })).then((e) => e)
}

export const sortItemCustomSpecifications = (itemId, payload) => {
    return axiosInstance.post(generatePath(ITEM_SINGLE_ACTION, {
        itemId,
        action: 'custom-specification/sort'
    }), payload)
}

export const addItemHiddenSpecification = (itemId, specId) => {
    return axiosInstance.post(generatePath(HIDDEN_SPECIFICATION_SINGLE, {
        itemId,
        specId,
    })).then(({ message }) => message)
}

export const deleteItemHiddenSpecification = (itemId, specId) => {
    return axiosInstance.delete(generatePath(HIDDEN_SPECIFICATION_SINGLE, {
        itemId,
        specId,
    })).then(({ message }) => message)
}

export const sendCommentToOrderManager = (itemId, comment) => {
    return axiosInstance.post(generatePath(ITEM_SINGLE_ACTION, {
        itemId,
        action: 'order-comment',
    }), {
        comment
    }).then(({ message }) => message)
}


export const getItemOrderSalesCommentHistory = (itemId) => {
    return axiosInstance.get(generatePath(ITEM_SINGLE_ACTION, {
        itemId,
        action: 'order-comment',
    })).then(({ data }) => data)
}
// export const updateProjectDetails = (id, payload) => {
//     return axiosInstance.patch(generatePath(ITEM_SINGLE, {
//         id
//     }), payload)
// }


export const getItemPrices = (itemId) => {
    return axiosInstance.get(generatePath(ITEM_SINGLE_ACTION, {
        itemId,
        action: 'default-price',
    })).then(({ data }) => data)
}

export const declineAlert = (itemId, alertId, comment) => {
    console.log({ itemId, alertId })
    return axiosInstance.post(generatePath(ITEM_SINGLE_ACTION_SINGLE, {
        itemId,
        action: 'action',
        id: alertId,
        secondaryAction: 'canceled'
    }), {
        comment
    }).then(({ message }) => message)
}