import { SearchOutlined } from '@ant-design/icons';
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { getProductDetails, searchProducts } from '../../api/product';
import Languages from '../../library/dynamics/languages';

const fetchSelectedProduct = async (id) => {
    const product = await getProductDetails(id)
    if (!product) {
        return null
    }
    return { label: product.name, value: product.id }
}

function ProductSearch({ language, value = null, onChange, disabled = false, ...otherProps }) {
    const debounceTimeout = 800
    const [fetching, setFetching] = useState(false)
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    const searchQueryRef = useRef('')
    const selectedProductId = !isNaN(parseInt(value)) ? parseInt(value) : null

    useEffect(() => {
        if (selectedProductId) {
            fetchSelectedProduct(selectedProductId).then(p => setOptions([p]))
        }
        // .then(() => debounceFetcher(null))
    }, [])

    const debounceFetcher = useMemo(() => {
        const loadOptions = async (_value = null) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            if (!_value || _value.length < 3) {
                const _product = await getProductDetails(value)
                if (_product) {
                    setOptions([
                        { label: _product.name?.[language], value: _product.id }
                    ])
                }
                return
            }
            setFetching(true);
            searchProducts(_value, null, language).then(async (newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                setOptions(newOptions);
                setFetching(false);
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [language, searchProducts, debounceTimeout]);

    return (
        <>
            <Select
                style={{ width: '70%' }}
                {...otherProps}
                filterOption={false}
                onSearch={(v) => {
                    searchQueryRef.current = v
                    debounceFetcher(v)
                }}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                options={options}
                onChange={(id) => {
                    if (!id || !parseInt(id)) {
                        onChange(null)
                        return
                    }
                    getProductDetails(id)
                        .then((data) => {
                            onChange(id, data)
                        })
                }}
                value={selectedProductId}
                placeholder="Select a Product"
                suffixIcon={<SearchOutlined />}
                showSearch
                allowClear
                disabled={disabled}
            />
        </>
    )
}

ProductSearch.propTypes = {
    language: PropTypes.oneOf(Languages.map(({ cms_language_code }) => cms_language_code)).isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
}

export default ProductSearch;