import Icon, {
  BarChartOutlined, DropboxOutlined,
  EuroCircleOutlined,
  FormatPainterOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
  UserOutlined,
  UsergroupDeleteOutlined,
} from "@ant-design/icons";

import React from 'react';
import { generatePath } from 'react-router-dom';
import { AddInvoiceLine } from '../../components/data';
import { ContactCreate, ContactDetails, Contacts } from "../../routes/contacts";
import { CustomerCreate, CustomerDetails, Customers } from "../../routes/customers";
import { Dashboard } from "../../routes/dashboard";
import { CreatePaymentLink, EulerCaseDetails, EulerCasesList, FinanceProjectDetails, FinanceProjects, InvoiceDetails, Invoices, ManualPayment, PurchaseBillDetails, PurchaseBillLink, PurchaseBills } from '../../routes/finance';
import { PurchaseBillBasicDetails, UpdatePurchaseBillBasicDetails } from '../../routes/finance/purchasebill-basic-details.jsx';
import SepaPaymentDetails from "../../routes/finance/sepa-payment-details.jsx";
import SepaPaymentFiles from "../../routes/finance/sepa-payment-file-list.jsx";
import SentCombiDetails from '../../routes/orderManager/combi-deals/sent-combi-details.jsx';
import CustomerArtworkToVendor from "../../routes/orderManager/customer-artwork-to-vendor.jsx";
import { CombiDealsDetails, CombiDealsSuggestion, OrderDetails, OrderManagerList, Review, SentCombiDeals } from '../../routes/orderManager/index';
import MakeCustomerArtwork from "../../routes/orderManager/make-customer-artwork.jsx";
import OrderManagerFileTransfer from "../../routes/orderManager/order-manager-file-transfer.jsx";
import SendArtworkToCustomer from "../../routes/orderManager/send-artwork-to-customer.jsx";
import SendProofToCustomer from "../../routes/orderManager/send-proof-to-customer.jsx";
import UpdateProof from "../../routes/orderManager/update-proof.jsx";
import { ProcjectCancellationInvoice, ProjectCreate, ProjectUpdate, Projects, SendClientQuotation } from "../../routes/projects";
import { AdditionalQuotation, AdditionalQuotationPreview, ProjectItemCreate, ProjectItemUpdate, VendorQuotation } from '../../routes/projects/items';
import { GeneralLedgers, LedgerCreate, LedgerDetails, TemplateDetails, TextTemplates, VAT, VATCreate, VATDetails } from '../../routes/settings';
import EmailHistory from "../../routes/settings/email-history.jsx";
import EmailTemplateDetails from "../../routes/settings/template-details.jsx";
import EmailTemplateList from "../../routes/settings/template-list.jsx";
import { StudioItemDetails, StudioList } from '../../routes/studio';
import { SendReadyArtwork, SendReadyProof, SendReadyTemplate, UploadSampleTemplate } from '../../routes/template';
import EmailDetails from "../../routes/template/email-details.jsx";
import { UserCreate, UserDetails, Users } from '../../routes/users';
import { VendorCreate, VendorCreateUser, VendorUpdate, VendorUserDetails, Vendors } from "../../routes/vendors";
import { OrderManagerSteps } from './steps';
import { UserPermissions } from "./userPermissions.js";
import { UserRoleTypes } from './userRoles';

// const checkNonSubmissionReload = ({ currentUrl, nextUrl, formData, json }) => {
//   return !!formData || !!json || currentUrl.pathname !== nextUrl.pathname
// }

const getOrderManagerSteps = () => OrderManagerSteps.map(({ id, title, status, component }) => ({
  url: `/order-manager/:itemId/${status}`,
  title,
  id,
  component: component()
}))

export const routesList = [
  {
    label: 'Dashboard',
    title: 'Dashboard',
    // url: '/',
    url: '/dashboard',
    component: Dashboard,
    id: 'Dashboard',
    icon: <BarChartOutlined />,
    showOnMenu: true,
    roles_allowed: [UserPermissions.DASHBOARD],
  },
  {
    label: 'Projects',
    title: 'Projects',
    url: '/projects',
    //component: Projects,
    icon: <ShoppingCartOutlined />,
    showOnMenu: true,
    roles_allowed: [UserPermissions.PROJECT_LIST],
    children: [
      {
        label: 'Project List',
        title: 'Project List',
        component: Projects,
        id: 'ProjectList',
        showOnMenu: true,
        roles_allowed: [UserPermissions.PROJECT_LIST],
      },
      {
        label: 'Create Project',
        title: 'Create Project',
        url: '/projects/create',
        component: ProjectCreate,
        id: 'ProjectCreate',
        showOnMenu: true,
        roles_allowed: [UserPermissions.CREATE_PROJECT],
      },
      {
        url: '/projects/:projectId',
        showOnMenu: false,
        children: [
          {
            label: 'Update Project',
            title: 'Update Project / ${projectId}',
            component: ProjectUpdate,
            showOnMenu: false,
            url: '/projects/:projectId',
            id: 'ProjectUpdate',
          },
          {
            label: 'Additional Quotation',
            title: 'Additional Quotation',
            url: '/projects/:projectId/additional-quotation/:itemId',
            showOnMenu: false,
            component: AdditionalQuotation,
            id: 'AdditionalQuotation',

            children: [
              {
                label: 'Additional Quotation Preview',
                title: 'Additional Quotation Preview',
                url: '/projects/:projectId/additional-quotation/:itemId/preview/:id',
                showOnMenu: false,
                component: AdditionalQuotationPreview,
                id: 'AdditionalQuotationPreview',

              }
            ]
          },
          {
            label: 'Send Quotation to Client',
            title: 'Send Quotation to Client',
            url: '/projects/:projectId/client-quotation',
            component: SendClientQuotation,
            id: 'SendClientQuotation',
            showOnMenu: false,
            roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
          },
          {
            label: 'Create Project Item',
            title: 'Create Item',
            url: '/projects/:projectId/items/create',
            component: ProjectItemCreate,
            id: 'ProjectItemCreate',
            showOnMenu: false,
          },
          {
            label: 'Update Project Item',
            title: 'Project Item',
            url: '/projects/:projectId/items/:itemId',
            component: ProjectItemUpdate,
            id: 'ProjectItemUpdate',
            showOnMenu: false,
            children: [
              {
                url: '/projects/:projectId/items/:itemId/vendorquotation/:vendorId',
                component: VendorQuotation,
                id: 'VendorQuotation'
              }
            ]
          },
          {
            label: 'Create Cancellation Invoice',
            title: 'Create Project Cancellation Invoice/ ${projectId}',
            component: ProcjectCancellationInvoice,
            showOnMenu: false,
            url: '/projects/:projectId/cancel',
            id: 'ProjectCancellationInvoiceCreate',
          }
        ]
      },
    ]
  },
  {
    label: 'Customers',
    title: 'Customers',
    url: '/customers',
    //nolink: true,
    icon: <UsergroupDeleteOutlined />,
    showOnMenu: true,
    roles_allowed: [UserPermissions.CUSTOMER],
    children: [
      {
        label: 'Company',
        title: 'Company',
        component: Customers,
        id: 'Customers',
        showOnMenu: true,
        roles_allowed: [UserPermissions.CUSTOMER],
      },
      {
        label: 'Customers Details',
        title: 'Customers Details',
        url: '/customers/:id',
        component: CustomerDetails,
        id: 'CustomerDetails',
        showOnMenu: false,
      },
      {
        label: 'Create Customer',
        title: 'Create Customer',
        url: '/customers/create',
        component: CustomerCreate,
        id: 'CustomerCreate',
        showOnMenu: false,
      },
      {
        label: 'Contacts',
        title: 'Contacts',
        url: '/customers/contacts',
        showOnMenu: true,
        roles_allowed: [UserPermissions.CONTACT],
        children: [
          {
            label: 'Contacts',
            title: 'Contacts',
            component: Contacts,
            id: 'Contacts',
            showOnMenu: false,
          },
          {
            label: 'Create Contact',
            title: 'Create Contact',
            url: '/customers/contacts/create',
            component: ContactCreate,
            id: 'ContactCreate',
            showOnMenu: false,
          },
          {
            label: 'Contacts',
            title: 'Contacts',
            url: '/customers/contacts/:id',
            component: ContactDetails,
            id: 'ContactDetails',
            showOnMenu: false,
          },
        ]
      },
    ]
  },
  {
    label: 'Studio',
    title: 'Studio',
    url: '/studio-orders',
    showOnMenu: true,
    icon: <FormatPainterOutlined />,
    roles_allowed: [UserPermissions.STUDIO_LIST],
    children: [
      {
        label: 'Studio',
        title: 'Studio',
        component: StudioList,
        id: 'StudioList',
        showOnMenu: false,
      },
      {
        label: 'Studio Item Details',
        title: 'Studio Item Details',
        url: '/studio-orders/:id',
        component: StudioItemDetails,
        id: 'StudioItemDetails',
        showOnMenu: false,
      },
    ]
  },
  {
    label: 'Order Manager',
    title: 'order Manager',
    url: '/order-manager',
    icon: <UsergroupDeleteOutlined />,
    showOnMenu: true,
    roles_allowed: [UserPermissions.ORDER_MANAGER_LIST],
    children: [
      {
        label: 'Order Manager',
        title: 'order Manager',
        component: OrderManagerList,
        id: 'OrderManagerList',
        icon: <UserOutlined />,
        showOnMenu: true,
        roles_allowed: [UserPermissions.ORDER_MANAGER_LIST],
      },
      {
        label: 'Order Manager',
        title: 'Order Manager',
        url: '/order-manager/:itemId',
        component: OrderDetails,
        id: 'OrderManagerDetailsPage',
        showOnMenu: false,
        children: getOrderManagerSteps(),
      },
      {
        label: 'File Transfer',
        title: 'File Transfer',
        url: '/order-manager/:itemId/file-transfer',
        component: OrderManagerFileTransfer,
        id: 'OrderManagerFileTransfer',
        showOnMenu: false,
        children: [
          {
            label: 'Make Artwork for Customer',
            title: 'Make Artwork for Customer',
            url: '/order-manager/:itemId/file-transfer/make-artwork',    //this is for sending edit artwork to customer so he can approve it
            component: MakeCustomerArtwork,
            id: 'MakeCustomerArtwork',
            showOnMenu: false,
          },
          {
            label: 'Send Artwork to Customer',
            title: 'Send Artwork to Customer',
            url: '/order-manager/:itemId/file-transfer/artwork-to-customer',     // sending artowrk to customer 
            component: SendArtworkToCustomer,
            id: 'SendArtworkToCustomer',
            showOnMenu: false,
          },
          {
            label: 'Send Artwork to Vendor',
            title: 'Send Artwork to Vendor',
            url: '/order-manager/:itemId/file-transfer/artwork-to-vendor',
            component: CustomerArtworkToVendor,
            id: 'CustomerArtworkToVendor',
            showOnMenu: false,
          },
          {
            label: 'Send Artwork to Vendor',
            title: 'Send Artwork to Vendor',
            url: '/order-manager/:itemId/file-transfer/proof-to-customer',
            component: SendProofToCustomer,
            id: 'SendProofToCustomer',
            showOnMenu: false,
          },
          {
            label: 'Update Printable Proof',
            title: 'Update Printable Proof',
            url: '/order-manager/:itemId/file-transfer/update-proof',
            component: UpdateProof,
            id: 'UpdateProof',
            showOnMenu: false,
          },
          {
            label: 'Send Ready Artwork to Vendor',
            title: 'Send Ready Artwork to vendor',                      //this is when order send artwork to vendor so he can give him printable final proof
            url: '/order-manager/:itemId/file-transfer/send-ready-artwork',
            id: 'SendReadyArtwork',
            component: SendReadyArtwork,
            showOnMenu: false,
          },
          {
            label: 'Send Ready Proof to Customer',
            title: 'Send Ready Proof To Customer',
            url: '/order-manager/:itemId/file-transfer/send-ready-proof',
            component: SendReadyProof,
            id: 'SendReadyProof',
            showOnMenu: false,
          },
          {
            label: 'Upload Sample Template',
            title: 'Upload Sample Template',
            url: '/order-manager/:itemId/file-transfer/upload-sample-template',
            id: 'UploadSampleTemplate',
            component: UploadSampleTemplate,
            showOnMenu: false,
          },

          {
            label: 'Send ready Template to Customer',
            title: 'Send ready Template to Customer',
            url: '/order-manager/:itemId/file-transfer/send-ready-template',
            component: SendReadyTemplate,
            id: 'SendReadyTemplate',
            showOnMenu: false,
          },

        ],
      },
      {
        label: 'Combi Deals Suggestion',
        title: 'Combi Deals Suggestion',
        component: CombiDealsSuggestion,
        id: 'CombiDealsSuggestion',
        url: '/order-manager/combi-deals-suggestion',
        icon: <Icon component={() => (<img src="/icons/dot.svg" />)} />,
        roles_allowed: [UserPermissions.COMBI_DEALS],
        showOnMenu: true,
      },
      {
        label: 'Sent Combi Deals List',
        title: 'Combination Deals',
        component: SentCombiDeals,
        id: 'SentCombiDeals',
        url: '/order-manager/sent-combi-deals',
        icon: <Icon component={() => (<img src="/icons/dot.svg" />)} />,
        roles_allowed: [UserPermissions.SENT_COMBI_DEALS],
        showOnMenu: true,
      },
      // {
      //   label: 'Order Manager',
      //   title: 'Order Manager',
      //   component: OrderManagerList,
      //   showOnMenu: true,
      //   // icon: <Icon component={() => (<img src="/icons/dot.svg" />)} />,
      //   roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
      // },
    ]
  },
  {
    label: 'Combi Deals Details',
    title: 'Combination Deals',
    component: CombiDealsDetails,
    id: 'CombiDealsDetails',
    url: '/combi-deals-suggestion/:productId',
    icon: <Icon component={() => (<img src="/icons/dot.svg" />)} />,
    showOnMenu: false,
    roles_allowed: [UserPermissions.COMBI_DEALS],
  },
  {
    label: 'Sent Combination Deals Details',
    title: 'Sent Combination Deals Details',
    component: SentCombiDetails,
    id: 'SentCombiDetails',
    url: '/sent-combi-deals/:id',
    icon: <Icon component={() => (<img src="/icons/dot.svg" />)} />,
    showOnMenu: false,
    roles_allowed: [UserPermissions.COMBI_DEALS],
  },
  {
    label: 'Review Request',
    title: 'Review Request',
    component: Review,
    id: 'CombidealReview',
    url: 'combi-deals-suggestion/:productId/review',
    icon: <Icon component={() => (<img src="/icons/dot.svg" />)} />,
    showOnMenu: false,
    roles_allowed: [UserPermissions.COMBI_DEALS],
  },


  // {
  //   label: 'Send Artwork',
  //   title: 'Send Artwork',
  //   url: '/send-direct-artwork/:id',
  //   component: SendDirectArtwork,
  //   id: 'SendDirectArtwork',
  //   showOnMenu: false,
  // },
  // {
  //   label: 'Vendor Artwork',
  //   title: 'Prinatable Final Proof',
  //   url: '/vendor-artwork/:id',
  //   component: VendorArtwork,
  //   id: 'VendorArtwork',
  //   showOnMenu: false,
  // },
  {
    label: 'Vendors',
    title: 'Vendors',
    url: '/vendors',
    icon: <TeamOutlined />,
    // component: Vendors,
    showOnMenu: true,
    roles_allowed: [UserPermissions.VENDOR_LIST],
    children:
      [{
        label: 'Add New Vendor',
        title: 'Add New Vendor',
        url: '/vendors/create',
        component: VendorCreate,
        id: 'VendorCreate',
        showOnMenu: true,
        roles_allowed: [UserPermissions.ADD_VENDOR],
      },
      {
        label: 'Vendors',
        title: 'Vendors',
        component: Vendors,
        id: 'Vendors',
        showOnMenu: true,
        roles_allowed: [UserPermissions.VENDOR_LIST],
      },
      {
        label: 'Vendor Details',
        title: 'Vendor Details',
        url: '/vendors/:id',
        component: VendorUpdate,
        id: 'VendorUpdate',
        showOnMenu: false,
        children: [
          {
            label: 'Create Vendor User',
            title: 'Create Vendor User',
            url: '/vendors/:id/user-create',
            component: VendorCreateUser,
            id: 'VendorCreateUser',
            showOnMenu: false,
          },
          {
            label: 'Vendor User',
            title: 'Vendor User',
            url: '/vendors/:id/user/:uId',
            component: VendorUserDetails,
            id: 'VendorUserDetails',
            showOnMenu: false,
          }
        ]
      }

      ]
  },
  {
    label: 'Finance',
    title: 'Finance',
    showOnMenu: true,
    url: '/finance',
    icon: <EuroCircleOutlined />,
    nolink: true,
    roles_allowed: [UserPermissions.FINANCE_PROJECT, UserPermissions.FINANCE_INVOICE],
    children: [
      {
        label: 'Projects',
        title: 'Projects',
        url: '/finance/projects',
        icon: <DropboxOutlined />,
        showOnMenu: true,
        children: [
          {
            label: 'Projects',
            title: 'Projects',
            component: FinanceProjects,
            id: 'FinanceProjects',
            roles_allowed: [UserPermissions.FINANCE_PROJECT],
          },
          {
            label: 'Project Details',
            title: 'Project Details',
            url: '/finance/projects/:projectId',
            component: FinanceProjectDetails,
            roles_allowed: [UserPermissions.FINANCE_PROJECT],
            id: "FinanceProjectDetails",
            shouldRevalidate: ({ currentUrl, nextUrl, formData, json }) => {
              return !!formData || !!json || currentUrl.pathname !== nextUrl.pathname
            },
          }
        ]
      },
      {
        label: 'Invoices',
        title: 'Invoices',
        url: '/finance/invoices',
        icon: <DropboxOutlined />,
        showOnMenu: true,
        roles_allowed: [UserPermissions.FINANCE_INVOICE],
        children: [
          {
            label: 'Invoices',
            title: 'Invoices',
            component: Invoices,
            id: 'Invoices',
          },
          {
            label: 'Invoice Details',
            title: 'Invoice Details',
            url: '/finance/invoices/:invoiceId',
            component: InvoiceDetails,
            id: 'InvoiceDetails',
            children: [
              {
                title: 'Invoice Details',
                url: '/finance/invoices/:invoiceId/edit/addinvoiceline',
                component: AddInvoiceLine,
                id: 'AddInvoiceLine',
              },
              {
                label: 'Invoice Details: Payment Link',
                title: 'Project Details',
                url: '/finance/invoices/:invoiceId/create-paylink',
                id: "FinanceInvoiceDetailsCreatePaylink",
                component: CreatePaymentLink,
                showOnMenu: false
              },
              {
                label: 'Record Offline Payment',
                title: 'Record Offline Payment',
                url: '/finance/invoices/:invoiceId/offline-payment',
                component: ManualPayment,
                id: 'ManualPayment',
                showOnMenu: false
              },
            ]
          },
        ]
      },
      {
        label: 'Purchase Bills',
        title: 'Purchase Bills',
        url: '/finance/purchase-bills',
        icon: <DropboxOutlined />,
        showOnMenu: true,
        roles_allowed: [UserPermissions.PURCHASE_BILLS],
        children: [
          {
            label: 'Purchase Bills',
            title: 'Purchase Bills',
            component: PurchaseBills,
            id: 'PurchaseBills',
          },
          {
            label: 'Purchase Bill Create',
            title: 'Purchase Bill Create',
            url: '/finance/purchase-bills/:purchaseBillId/create',
            component: PurchaseBillLink,
            id: 'PurchaseBillLink',
            showOnMenu: false,
          },
          {
            label: 'Purchase Bill Details',
            title: 'Purchase Bill Details',
            url: '/finance/purchase-bills/:purchaseBillId',
            id: 'FinancePurchaseBillDetails',
            component: PurchaseBillDetails,
            children: [
              {
                label: 'Purchase Bills',
                title: 'Purchase Bills',
                component: PurchaseBillBasicDetails,
                id: 'PurchaseBillBasicDetails',
              },
              {
                url: '/finance/purchase-bills/:purchaseBillId/edit',
                component: UpdatePurchaseBillBasicDetails,
                id: 'UpdatePurchaseBillBasicDetails',
              }
            ]
          }
        ]
      },
      {
        label: 'Sepa Payment Files',
        title: 'Sepa Payment Files',
        url: '/finance/sepa-payment-files',
        icon: <DropboxOutlined />,
        showOnMenu: true,
        roles_allowed: [UserPermissions.PURCHASE_BILLS],
        children: [
          {
            label: 'Sepa Payment Files',
            title: 'Sepa Payment Files',
            component: SepaPaymentFiles,
            id: 'SepaPaymentFiles',
          },
          {
            label: 'Sepa Payment File Details',
            title: 'Sepa Payment File Details',
            url: '/finance/sepa-payment-files/:sepaPaymentId',
            component: SepaPaymentDetails,
            id: 'SepaPaymentDetails',
          }
        ]
      },
      {
        label: 'Euler Cases',
        title: 'Euler Cases',
        url: '/finance/euler-cases',
        icon: <DropboxOutlined />,
        showOnMenu: true,
        roles_allowed: [UserPermissions.EULER],
        children: [
          {
            title: 'Euler Cases',
            component: EulerCasesList,
            id: 'EulerCasesList',
            icon: <DropboxOutlined />,

          },
          {
            label: 'Euler Case Details',
            title: 'Euler Case Details',
            url: '/finance/euler-cases/:id',
            id: "EulerCaseDetails",
            component: EulerCaseDetails,
            showOnMenu: false,
          },
        ]


      }
    ]
  },
  {
    label: 'Users',
    title: 'Users',
    url: '/users',
    component: Users,
    icon: <UserOutlined />,
    showOnMenu: true,
    roles_allowed: [UserPermissions.USER],
    children: [
      {
        title: 'User Details',
        label: 'User Details',
        url: '/users/:userId',
        component: UserDetails,
        id: 'UserDetails',
        showOnMenu: false
      },
      {
        title: 'Create New User',
        label: 'Create New User',
        url: '/users/new',
        component: UserCreate,
        id: 'UserCreate',
        showOnMenu: false
      }
    ]
  },

  {
    label: 'Settings',
    title: 'Settings',
    url: '/settings',
    icon: <SettingOutlined />,
    showOnMenu: true,
    nolink: true,
    roles_allowed: [UserPermissions.SETTINGS],
    children: [
      {
        label: 'Email Templates',
        title: 'Email Templates',
        url: '/settings/email-templates',
        showOnMenu: true,
        children: [
          {
            title: 'Email Template Update',
            component: EmailTemplateList,
            id: 'EmailTemplateList',
            showOnMenu: false
          },
          {
            title: 'Email Template Update',
            label: 'Email Template Update',
            url: '/settings/email-templates/:id/:language',
            component: EmailTemplateDetails,
            id: 'EmailTemplateDetails',
            showOnMenu: false,
          },
          {
            title: 'Email History',
            label: 'Email History',
            url: '/settings/email-templates/:id/history',
            component: EmailHistory,
            id: 'EmailHistory',
            showOnMenu: false,
            children: [
              {
                title: 'User Details',
                label: 'User Details',
                url: '/settings/email-templates/:id/history/:emailId',
                component: EmailDetails,
                id: 'EmailDetails',
                showOnMenu: false
              },
            ]
          },
        ]
      },
      {
        label: 'Text Templates',
        title: 'Text Templates',
        url: '/settings/texttemplates',
        // icon: <SettingOutlined />,
        component: TextTemplates,
        id: 'TextTemplates',
        showOnMenu: true,
        children: [
          {
            label: 'Text Templates Details',
            title: 'Text Templates Details',
            url: '/settings/texttemplates/:templateId',
            component: TemplateDetails,
            id: 'TemplateDetails',
            showOnMenu: false,
          }
        ]
      },
      {
        label: 'VAT Types',
        title: 'VAT Types',
        url: '/settings/VAT',
        component: VAT,
        id: 'VAT',
        showOnMenu: true,
        children: [
          {
            title: 'VAT Details',
            label: 'VAT Details',
            url: '/settings/VAT/VATDetails/:id',
            component: VATDetails,
            id: 'VATDetails',
            showOnMenu: false
          },
          {
            title: 'Create VAT',
            label: 'Create VAT',
            url: '/settings/VAT/VATCreate',
            component: VATCreate,
            id: 'VATCreate',
            showOnMenu: false
          }
        ]
      },
      {
        label: 'General Ledgers',
        title: 'General Ledgers',
        url: '/settings/general-ledgers',
        // icon: <SettingOutlined />,
        component: GeneralLedgers,
        id: 'GeneralLedgers',
        showOnMenu: true,
        children: [
          {
            title: 'Ledger Details',
            label: 'Ledger Details',
            url: '/settings/general-ledgers/:id',
            component: LedgerDetails,
            id: 'LedgerDetails',
            showOnMenu: false
          },
          {
            title: 'Create Ledger',
            label: 'Create Ledger',
            url: '/settings/general-ledgers/create',
            component: LedgerCreate,
            id: 'LedgerCreate',
            showOnMenu: false
          }
        ]
      }
    ]
  }
];

const flattenRoutes = (route, parent = []) => (
  route.children ?
    [{ url: route?.url ?? parent, component: route?.component, id: route?.id }, ...route.children.map(_r => flattenRoutes(_r, [...parent, route?.url]))] :
    { url: route?.url ?? parent, component: route?.component, id: route?.id }
)

export const generateRouteUrl = (componentName, params = {}) => {
  const matchedRoute = routesList.map(route => flattenRoutes(route))
    .flat(Infinity)
    .filter(route => route?.id)
    .find(route => route?.id === componentName)

  if (!matchedRoute)
    throw new Error(`Unable to generate URL: Invalid Route Id: ${componentName}`)

  let url = ''
  if (Array.isArray(matchedRoute.url)) {
    for (const i of matchedRoute.url.reverse()) {
      url += i
      if (i.startsWith('/')) break
    }
  }
  else {
    url = matchedRoute.url
  }

  return generatePath(url, params)
}

export const generateRouteUrlById = (routeId, params = {}) => {
  const matchedRoute = routesList.map(route => flattenRoutes(route))
    .flat(Infinity)
    .filter(route => route?.id)
    .find(route => route?.id === routeId)

  if (!matchedRoute)
    throw new Error(`Unable to generate URL: Invalid Route Id: ${routeId}`)

  let url = ''
  if (Array.isArray(matchedRoute.url)) {
    for (const i of matchedRoute.url.reverse()) {
      url += i
      if (i.startsWith('/')) break
    }
  }
  else {
    url = matchedRoute.url
  }

  return generatePath(url, params)
}