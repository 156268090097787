import { DatePicker as AntdDatePicker, ConfigProvider } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { formats } from '../../library/constants';

import locale from "antd/es/locale/en_GB";
import "moment/locale/en-gb";

moment.locale("en-gb", {
  week: {
    dow: 1,
  },
});
const DatePicker = ({ value = null, disabledBefore, disabledAfter, onChange, useHidden, ...otherProps }) => {

  const formattedDate = () => (value ? moment(value) : value)
  const [val, setVal] = useState(formattedDate()?.format(formats.backendDateFormat))
  const extraProps = { ...otherProps }
  if (useHidden && extraProps.name) {
    delete extraProps.name
  }
  const parseDate = (dateString) => {
    return moment(dateString, formats.backendDateFormat);
  };

  const disableDate = (current) => {
    if (disabledAfter) {
      const afterDate = parseDate(disabledAfter);
      if (current && current > afterDate.endOf('day')) {
        return true;
      }
    }
    if (disabledBefore) {
      const beforeDate = parseDate(disabledBefore);
      if (current && current < beforeDate.startOf('day')) {
        return true;
      }
    }
    if (!disabledBefore && current && current < moment().startOf('day')) {
      return true;
    }
    return false;
  };

  return (
    <>
      <ConfigProvider locale={locale}>
        <AntdDatePicker
          key={value}
          defaultValue={formattedDate}
          superNextIcon={false}
          superPrevIcon={false}
          onChange={(date) => {
            const newDate = date?.format(formats.backendDateFormat) || null
            setVal(newDate)
            onChange && onChange(newDate)
          }}
          format={formats.frontendDateFormat}
          disabledDate={disableDate}
          {...extraProps}
        />
        {useHidden && <input type="hidden" name={otherProps.name} value={val ?? ''} />}
      </ConfigProvider>

    </>
  )
}

DatePicker.propTypes = {
  value: function (props, propName, componentName) {
    if (props[propName] && !/[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(props[propName])) {
      return new Error(
        `Invalid value ${props[propName]}  for prop ${propName} supplied to ${componentName}. Validation failed.`
      );
    }
  },
  useHidden: PropTypes.bool,
  onChange: PropTypes.func,
  disabledBefore: PropTypes.string,
  disabledAfter: PropTypes.string
}

export default DatePicker;