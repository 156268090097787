import { DeleteOutlined, EditOutlined, SaveOutlined, UserAddOutlined } from '@ant-design/icons'
import { Button, Collapse, Form, Statistic, Tooltip, message } from 'antd'
import { sortBy } from 'lodash-es'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Await, Outlet, defer, useFetcher, useLoaderData, useNavigate, useParams } from 'react-router-dom'
import { createAddress, deleteAddress, updateAddress } from '../../api/address'
import { getVendorBasicStats, getVendorCompletedProjects, getVendorDetails, getVendorProducts, getVendorPurchaseBills, getVendorRunningProjects, getVendorUser, updateVendorDetails, updateVendorUser } from '../../api/vendor/vendor'
import { createVendorContact, deleteVendorContact, removeContactOrder, setContactEnquiry, setContactOrder, updateVendorContactDetails } from '../../api/vendor/vendorContact.js'
import styles from '../../components/data/project-item/select-vendors.module.scss'
import { SmallSection } from '../../components/design'
import { VendorAddress, VendorContact, VendorDetails } from '../../components/form'
import ProfileUpload from '../../components/inputs/ProfilePhoto.jsx'
import Confirm from '../../components/message/Confirm.jsx'
import { Page } from '../../components/page'
import { PaginationTable } from '../../components/table'
import { ValidationError } from '../../library/classes/exceptions/index.js'
import { ERP_CURRENCY } from '../../library/constants/dynamic.js'
import { generateRouteUrl } from '../../library/constants/index.js'
import { OrderStatusNameById } from '../../library/constants/OrderStatuses.js'
import { PurchaseBillStatusDetails } from '../../library/constants/purchase-bill-statuses.js'
import { convertDataTypes, getCountryById, searchParamsToObject } from '../../library/helpers/index.js'
import { getProductImageUrlResized } from '../../library/helpers/product_url.js'
import { formatDate, formatDateTime, formatPrice } from '../../library/utilities/intl.js'
import Image from '../../ui/image.jsx'
const { Panel } = Collapse

const OrderColumns = [
	{
		title: 'Project ID',
		dataIndex: 'project_id',
	},
	{
		title: 'Order Date',
		dataIndex: 'createdAt',
		render: formatDate
	},
	{
		title: 'Product',
		dataIndex: 'product_name',

	},
	{
		title: 'Quantity',
		dataIndex: 'quantity',
	},
	{
		title: 'Product Price',
		dataIndex: 'total_price',
		render: price => formatPrice(price, ERP_CURRENCY),
	},
	{
		title: 'Status',
		dataIndex: 'order_status',
		render: status => OrderStatusNameById(status)
	},
]
const PurchaseBillColumns = [
	{
		title: 'Project ID',
		dataIndex: 'project_id',
	},
	{
		title: 'Invoice No.',
		dataIndex: 'invoice_number',
	},
	{
		title: 'Product',
		dataIndex: 'item_text',

	},
	{
		title: 'Amount',
		dataIndex: 'custom_amount_to_pay',
		render: price => formatPrice(price, ERP_CURRENCY),
	},
	{
		title: 'Invoice Date',
		dataIndex: 'invoice_date',
		render: formatDate
	},
	{
		title: 'Due Date',
		dataIndex: 'due_date',
		render: formatDate
	},
	{
		title: 'Status',
		dataIndex: 'status',
		render: status => PurchaseBillStatusDetails?.[status]
	},
]

const ProductColumns = [
	{
		title: 'Item Name',
		dataIndex: 'name',
		render: (name, product) => (
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem' }}>
				<Image src={getProductImageUrlResized(product?.main_photos?.[0], 50)} alt={name} style={{ maxWidth: '50px' }} />
				{name}
			</div>
		),
	},
	// {
	// 	title: 'Item ID',
	// 	dataIndex: 'invoice_number',
	// },
	// {
	// 	title: 'Comments',
	// 	dataIndex: 'item_text',
	// },
]

const VendorUpdate = ({ title }) => {
	const { vendorUserData, vendorDetails, addresses, contacts, runningProjects, completedProjects, purchaseBills, productsAvailable } = useLoaderData()
	const { id: vendorId } = useParams()
	const [form] = Form.useForm()
	const defaultVendorDetails = { using_portal: true, discount: 0 }
	// const actionData = useActionData()
	const navigate = useNavigate()
	const [editableSection, setEditableSection] = useState()
	// const submit = useSubmit()
	const fetcher = useFetcher()
	const runningProductsFetcher = useFetcher()
	const completedProductsFetcher = useFetcher()
	const purchaseBillsFetcher = useFetcher()
	const productsFetcher = useFetcher()
	const changeEditableSection = (section = null, subSection = null) => {
		setEditableSection([section, subSection].filter(v => v).join(':'))
	}
	const vendorCountry = useMemo(() => getCountryById(vendorDetails.country_id), [vendorDetails.country_id])
	// useEffect(() => {
	// 	if (actionData && !actionData.errors) {
	// 		changeEditableSection()
	// 	}
	// }, [actionData])

	useEffect(() => {
		if (fetcher.data && !fetcher.data.errors) {
			changeEditableSection()
		}
	}, [fetcher.data])

	const addressSubmitButtonRefs = useRef({})
	const contactSubmitButtonRefs = useRef({})

	const handleVendorDetailsSubmit = async (details) => {
		fetcher.submit({ ...details, action: "updateBasicDetails" }, {
			method: "POST",
			encType: "application/json"
		})
	}
	const handleVendorAddressSubmit = async (_, address) => {
		fetcher.submit({ ...address, action: !address.id ? "addAddress" : "updateAddress" }, {
			method: "POST",
			encType: "application/json"
		})
	}
	const handleVendorContactSubmit = async (_, contact) => {
		fetcher.submit({ ...contact, action: !contact.id ? "addContact" : "updateContact" }, {
			method: "POST",
			encType: "application/json"
		})
	}

	const loadProductsPage = useCallback((page) => {
		const params = new URLSearchParams()
		params.set('index', '')
		params.set('loader', 'productsAvailable')
		params.set('page', page)
		productsFetcher.load('.?' + params.toString())
	}, [])

	const loadRunningProjectsPage = useCallback((page) => {
		const params = new URLSearchParams()
		params.set('index', '')
		params.set('loader', 'runningProjects')
		params.set('page', page)
		runningProductsFetcher.load('.?' + params.toString())
	}, [])

	const loadCompletedProjectsPage = useCallback((page) => {
		const params = new URLSearchParams()
		params.set('index', '')
		params.set('loader', 'completedProjects')
		params.set('page', page)
		completedProductsFetcher.load('.?' + params.toString())
	}, [])

	const loadPurchaseBillsPage = useCallback((page) => {
		const params = new URLSearchParams()
		params.set('index', '')
		params.set('loader', 'completedProjects')
		params.set('page', page)
		purchaseBillsFetcher.load('.?' + params.toString())
	}, [])

	const getAddressActions = (index, address, preventDelete = false) => {
		return ([
			editableSection === `address:${index}` ?
				<Button
					onClick={() => { addressSubmitButtonRefs.current[index].click() }}
					type="primary"
					shape="circle"
					icon={<SaveOutlined />}
					key="save"
					loading={address.__isLoading ?? false}
				/> :
				<Button
					onClick={() => { changeEditableSection('address', index) }}
					type="primary"
					shape="circle"
					icon={<EditOutlined />}
					key="edit"
				/>,
			index == "new" ?

				<Button type="primary" onClick={() => {
					changeEditableSection('address', null)
				}} shape="circle" icon={<DeleteOutlined />} danger />
				:
				<Confirm
					key="delete"
					content=''
					title="Are you sure to delete this address?"
					onConfirm={() => {
						const payload = { addressId: index }
						fetcher.submit({
							...payload,
							action: "deleteAddress",
						}, {
							method: "POST",
							encType: "application/json"
						})
					}}
					okText="Delete"
					cancelText="Cancel"
					icon={<DeleteOutlined />}
					type="primary"
					shape="circle"
					disabled={preventDelete}
					danger
				/>
		])
	}
	const getContactAction = (index, contact, preventDelete = false) => {
		return ([
			editableSection === `contact:${index}` ?
				<Tooltip title="Save" key="save">
					<Button onClick={() => contactSubmitButtonRefs.current[index].click()} type="primary" shape="circle" icon={<SaveOutlined />} key="save" loading={contact.__isLoading ?? false} />
				</Tooltip> :
				<Tooltip title="Edit" key="edit">
					<Button onClick={() => { changeEditableSection('contact', index) }} type="primary" shape="circle" icon={<EditOutlined />} key="edit" />
				</Tooltip>,
			index == "new" ?

				<Button type="primary" onClick={() => {
					changeEditableSection('contact', null)
				}} shape="circle" icon={<DeleteOutlined />} danger />
				:
				<Confirm
					key="delete"
					title="Are you sure to delete this contact?"
					content=''
					onConfirm={() => {
						fetcher.submit({
							action: "deleteContact",
							contactId: contact.id
						}, {
							method: "POST",
							encType: "application/json"
						})
					}}
					okText="Delete"
					cancelText="Cancel"
					icon={<DeleteOutlined />}
					type="primary"
					shape="circle"
					disabled={preventDelete}
					danger
				/>
		])
	}
	return (
		<Page className='vendor-detail' title={title} >
			<div className="headerStats">
				<React.Suspense
					fallback={<Statistic className='card' title="Previous Projects" value='' />}
				>
					<Await resolve={vendorDetails} >
						{(vendorDetails) => (
							<>
								<>
									<Statistic className='card' title="Completed Projects" value={(vendorDetails.completed_project != null) ? vendorDetails.completed_project : 0} />
									<Statistic className='card' title="Cancelled Projects" value={(vendorDetails.cancelled_project != null) ? vendorDetails.cancelled_project : 0} />
									<Statistic className='card' title="Rating" value={(vendorDetails.rating != null) ? Math.round(vendorDetails.rating) : 0} />
								</>
							</>
						)}
					</Await>
				</React.Suspense>
			</div>
			{vendorDetails &&
				<>
					<Collapse defaultActiveKey={['1']} expandIconPosition="end">
						<Panel header='Vendor Details' key="1" extra={
							<Button onClick={(event) => {
								event.stopPropagation()
								changeEditableSection('vendorDetails')
							}} disabled={editableSection === "basicDetails"}>Edit</Button>
						}>
							<VendorDetails id={Number(vendorId)} form={form} initialValues={Object.keys(vendorDetails).length == 0 ? defaultVendorDetails : vendorDetails} onFinish={(data) => handleVendorDetailsSubmit(data)}
								disabled={editableSection !== "vendorDetails"}
								loading={vendorDetails.__isLoading} errors={fetcher.data?.errors}>
								<div className="actions align--left">
									<Button type="primary" htmlType="submit">Save</Button>
									<Button onClick={() => {
										form.resetFields()
										changeEditableSection()
									}}>Cancel</Button>
								</div>
							</VendorDetails>
						</Panel>
					</Collapse>
					<Collapse defaultActiveKey={['1']} expandIconPosition="end">
						<Panel header='Addresses' key="1" >
							{addresses.map((address) => (
								<SmallSection
									key={address.id}
									actions={getAddressActions(address.id, address)}
								>
									<VendorAddress index={address.id} initialValues={address} onFinish={(data) => handleVendorAddressSubmit(address.id, { ...data, id: address.id })} disabled={editableSection !== `address:${address.id}`}>
										<Form.Item style={{ display: 'none' }}>
											<Button htmlType="submit" ref={el => addressSubmitButtonRefs.current[address.id] = el}>Save</Button>
										</Form.Item>
									</VendorAddress>
								</SmallSection>
							))}
							{editableSection === `address:new` && (
								<SmallSection actions={getAddressActions("new", {})} >
									<VendorAddress index={"new"} initialValues={{}} onFinish={(data) => handleVendorAddressSubmit("new", data)}>
										<Form.Item style={{ display: 'none' }}>
											<Button htmlType="submit" ref={el => addressSubmitButtonRefs.current['new'] = el}>Save</Button>
										</Form.Item>
									</VendorAddress>
								</SmallSection>
							)}
							<Button onClick={() => changeEditableSection('address', 'new')}>Add New Address</Button>
						</Panel>
					</Collapse>
					<Collapse defaultActiveKey={['1']} expandIconPosition="end">
						<Panel header='Contacts' key="1">
							{contacts.map((contact) => (
								<SmallSection
									key={contact.id}
									actions={getContactAction(contact.id, contact, contacts.length === 1)}>
									<VendorContact index={contact.id} initialValues={{ ...contact }} onFinish={(data) => handleVendorContactSubmit(contact.id, { ...data, id: contact.id })} disabled={editableSection !== `contact:${contact.id}`}>
										<Form.Item style={{ display: 'none' }}>
											<Button htmlType="submit" ref={el => contactSubmitButtonRefs.current[contact.id] = el}>Save</Button>
										</Form.Item>
									</VendorContact>
								</SmallSection>
							))}
							{editableSection === 'contact:new' && (
								<SmallSection actions={getContactAction("new", {})} >
									<VendorContact index={"new"} initialValues={{
										phone_country_code: vendorCountry?.phone_number_code,
										mobile_country_code: vendorCountry?.phone_number_code
									}} onFinish={(data) => handleVendorContactSubmit("new", data)}>
										<Form.Item style={{ display: 'none' }}>
											<Button htmlType="submit" ref={el => contactSubmitButtonRefs.current['new'] = el}>Save</Button>
										</Form.Item>
									</VendorContact>
								</SmallSection>
							)}
							<Button onClick={() => changeEditableSection('contact', 'new')}>Add New Contact</Button>
						</Panel>
					</Collapse>
					<div className={styles.usergrid} >
						{vendorUserData && vendorUserData.data.map((user) => {
							if (!user) return //Ignore empty users
							return (
								<Collapse key={user.id} defaultActiveKey={['1']} expandIconPosition="end">
									<Panel header='Vendor User Details' key="1" extra={
										<Button icon={<EditOutlined />} onClick={(event) => {
											event.stopPropagation()
											navigate(generateRouteUrl('VendorUserDetails', {
												id: vendorId,
												uId: user.id
											}))
										}} >Edit</Button>
									}>
										<div className={styles.userDetails}>
											<ProfileUpload user={user} />

											<p><b>Name :</b> {user.name}</p>
											<p> <b>UserName :</b>{user.username}</p>
											<p><b>Email :</b> {user.email}</p>
											<p><b>Created At :</b> {formatDateTime(user.created_at)}</p>
										</div>
									</Panel>
								</Collapse>
							)
						})}
					</div>
					<Outlet />
					<Button type="primary" onClick={() => {
						navigate(generateRouteUrl('VendorCreateUser', {
							id: vendorId
						}))
					}} icon={<UserAddOutlined />}>Add new User</Button>
					<br />
					<br />

					{/* <Collapse defaultActiveKey={['1']} expandIconPosition="end">
						<Panel header='Create Vendor User' key="1" extra={
							<Button onClick={() => { handleCreateVendorUser(form) }} icon={<EditOutlined />} >Create User</Button>}>
							<CreateVendorUser initialValue={vendorDetails} form={form} />
						</Panel>
					</Collapse> */}

				</>
			}
			<React.Suspense>
				<Await resolve={runningProductsFetcher.data ? runningProductsFetcher.data : runningProjects} >
					{({ data, meta }) => (
						<Collapse defaultActiveKey={['1']} expandIconPosition="end">
							<Panel header='Running Projects' key="1">
								<PaginationTable
									columns={OrderColumns}
									dataSource={data}
									rowKey="project_id"
									pageSize={meta?.limit}
									total={meta?.totalCount}
									totalPages={meta?.totalPages}
									currentPage={meta?.page}
									isLoading={runningProductsFetcher.state !== 'idle'}
									onPageChange={page => loadRunningProjectsPage(page)}
									onRow={row => ({
										onClick: () => {
											navigate(generateRouteUrl('ProjectUpdate', {
												projectId: row.project_id
											}))
										}
									})}
								/>
							</Panel>
						</Collapse>
					)}
				</Await>
			</React.Suspense>
			<React.Suspense>
				<Await resolve={completedProductsFetcher.data ? completedProductsFetcher.data : completedProjects} >
					{({ data, meta }) => (
						<Collapse defaultActiveKey={['1']} expandIconPosition="end">
							<Panel header='Completed Projects' key="1">
								<PaginationTable
									columns={OrderColumns}
									dataSource={data}
									rowKey="project_id"
									pageSize={meta?.limit}
									total={meta?.totalCount}
									totalPages={meta?.totalPages}
									currentPage={meta?.page}
									isLoading={completedProductsFetcher.state !== 'idle'}
									onPageChange={page => loadCompletedProjectsPage(page)}
									onRow={row => ({
										onClick: () => {
											navigate(generateRouteUrl('ProjectUpdate', {
												projectId: row.project_id
											}))
										}
									})}
								/>
							</Panel>
						</Collapse>
					)}
				</Await>
			</React.Suspense>
			<React.Suspense>
				<Await resolve={purchaseBillsFetcher.data ? purchaseBillsFetcher.data : purchaseBills} >
					{({ data, meta }) => (
						<Collapse defaultActiveKey={['1']} expandIconPosition="end">
							<Panel header='Purchase Bills' key="1">
								<PaginationTable
									columns={PurchaseBillColumns}
									dataSource={data}
									rowKey="id"
									pageSize={meta?.limit}
									total={meta?.totalCount}
									totalPages={meta?.totalPages}
									currentPage={meta?.page}
									isLoading={purchaseBillsFetcher.state !== 'idle'}
									onPageChange={page => loadPurchaseBillsPage(page)}
									onRow={row => ({
										onClick: () => {
											navigate(generateRouteUrl('FinancePurchaseBillDetails', {
												purchaseBillId: row.id
											}))
										}
									})}
								/>
							</Panel>
						</Collapse>
					)}
				</Await>
			</React.Suspense>
			<React.Suspense>
				<Await resolve={productsFetcher.data ? productsFetcher.data : productsAvailable} >
					{({ data, meta }) => (
						<Collapse defaultActiveKey={['1']} expandIconPosition="end">
							<Panel header='Items Available with this Vendor' key="1">
								<PaginationTable
									columns={ProductColumns}
									dataSource={data}
									rowKey="id"
									pageSize={meta?.limit}
									total={meta?.totalCount}
									totalPages={meta?.totalPages}
									currentPage={meta?.page}
									isLoading={productsFetcher.state !== 'idle'}
									onPageChange={page => loadProductsPage(page)}
									onRow={row => ({
										onClick: () => {
											navigate(generateRouteUrl('ProjectUpdate', {
												projectId: row.project_id
											}))
										}
									})}
								/>
							</Panel>
						</Collapse>
					)}
				</Await>
			</React.Suspense>
		</Page>
	)
}
VendorUpdate.propTypes = {
	title: PropTypes.string
}


VendorUpdate.Loaders = {
	default: async ({ params }) => {
		const { id } = params
		const { addresses = [], contacts = [], ...vendorDetails } = await getVendorDetails(id)
		const { ...vendorUserData } = await getVendorUser(id)
		return defer({
			vendorDetails,
			addresses: sortBy(addresses, 'id'),
			contacts: sortBy(contacts, 'id'),
			vendorUserData,
			stats: getVendorBasicStats(id),
			runningProjects: getVendorRunningProjects(id),
			completedProjects: getVendorCompletedProjects(id),
			purchaseBills: getVendorPurchaseBills(id),
			productsAvailable: getVendorProducts(id),
		})
	},
	runningProjects: async ({ params, request }) => {
		const { id } = params
		const url = new URL(request.url)
		const { page } = searchParamsToObject(url.searchParams)
		return await getVendorRunningProjects(id, page)
	},
	completedProjects: async ({ params, request }) => {
		const { id } = params
		const url = new URL(request.url)
		const { page } = searchParamsToObject(url.searchParams)
		return await getVendorCompletedProjects(id, page)
	},
	purchaseBills: async ({ params, request }) => {
		const { id } = params
		const url = new URL(request.url)
		const { page } = searchParamsToObject(url.searchParams)
		return await getVendorPurchaseBills(id, page)
	},
	productsAvailable: async ({ params, request }) => {
		const { id } = params
		const url = new URL(request.url)
		const { page } = searchParamsToObject(url.searchParams)
		return await getVendorProducts(id, page)
	},
}

VendorUpdate.Actions = {
	updateVendoruser: async ({ params, data }) => {
		const { id } = params
		try {
			data.payload.user_roles = ['vendor_admin', 'vendor_light_user', 'vendor_quotation']
			const { message: msg } = await updateVendorUser(id, data.payload)
			message.success(msg)
		}
		catch (error) {
			if (error instanceof ValidationError) {
				message.error(error.errors.email)
				return {
					errors: error.errors
				}
			}
			message.error(error.message)
		}
		return null
	},
	updateBasicDetails: async ({ params, data: requestData }) => {
		const { id } = params
		const payload = convertDataTypes(requestData, {
			using_portal: Boolean
		})
		try {
			const { message: msg, data } = await updateVendorDetails(id, payload)
			message.success(msg)
			return data
		}
		catch (error) {
			message.error(error.message)
			if (error instanceof ValidationError) {
				return {
					errors: error.errors
				}
			}
		}
		return null
	},
	addAddress: async ({ params, data: requestData }) => {
		const { id } = params
		const address = { ...requestData, context_id: id, context: 'vendor' }
		try {
			const { message: msg, data } = await createAddress(address)
			message.success(msg)
			return data
		}
		catch (error) {
			if (error instanceof ValidationError) {
				return {
					errors: error.errors
				}
			}
			message.error(error.message)
		}
		return null
	},
	updateAddress: async ({ params, data: requestData }) => {
		const { id } = params
		const { id: addressId, ...address } = { ...requestData, context_id: id, context: 'vendor' }
		try {
			const { message: msg, data } = await updateAddress(addressId, address)
			message.success(msg)
			return data
		}
		catch (error) {
			if (error instanceof ValidationError) {
				return {
					errors: error.errors
				}
			}
			message.error(error.message)
		}
		return null
	},
	deleteAddress: async ({ data: requestData }) => {
		const { addressId } = requestData
		try {
			const { message: msg } = await deleteAddress(addressId)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return null
	},
	addContact: async ({ params, data: requestData }) => {
		const { id } = params
		const contact = { ...requestData, vendor_id: id }
		try {
			const { message: msg, data } = await createVendorContact(contact)
			message.success(msg)
			return data
		}
		catch (error) {
			if (error instanceof ValidationError) {
				Object.entries(error.errors).forEach(([k, v]) => {
					const k_path = k.split('.')
					if (k_path.length > 1) {
						if (k_path[0] === 'addresses') {
							///Send it to address reducer
							const addressKey = k_path[1]
							message.error(addressKey)
						}
						else if (k_path[0] === 'contacts') {
							///Send it to contacts reducer
							const contactKey = k_path[1]
							message.error(contactKey)
						}
					}
					else {
						message.error(v)
						///Send it to basic details reducer
					}
				})
			}
			else {
				message.error(error)
			}
		}
		return null
	},
	updateContact: async ({ params, data: requestData }) => {
		const { id } = params
		const { id: contactId, ...contact } = { ...requestData, vendor_id: id }
		try {
			const { message: msg, data } = await updateVendorContactDetails(contactId, contact)
			message.success(msg)
			return data
		}
		catch (error) {
			if (error instanceof ValidationError) {
				Object.entries(error.errors).forEach(([k, v]) => {
					const k_path = k.split('.')
					if (k_path.length > 1) {
						if (k_path[0] === 'addresses') {
							///Send it to address reducer
							const addressKey = k_path[1]
							message.error(addressKey)
						}
						else if (k_path[0] === 'contacts') {
							///Send it to contacts reducer
							const contactKey = k_path[1]
							message.error(contactKey)
						}
					}
					else {
						message.error(v)
						///Send it to basic details reducer
					}
				})
			}
			else {
				message.error(error)
			}
		}
		return null
	},
	deleteContact: async ({ data: requestData }) => {
		const { contactId } = requestData
		try {
			const { message: msg } = await deleteVendorContact(contactId)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return null
	},
	contactOrder: async ({ data: requestData }) => {
		const { contactId } = requestData
		try {
			const { message: msg } = await setContactOrder(contactId)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	contactEnquiry: async ({ params, data: requestData, method }) => {
		const { id: customerId } = params
		const { contactId } = requestData
		if (method === 'DELETE') {
			try {
				const { message: msg } = await removeContactOrder(customerId, contactId)
				message.success(msg)
				return true
			}
			catch (error) {
				message.error(error.message)
			}
			return false
		}
		else {
			try {
				const { message: msg } = await setContactEnquiry(customerId, contactId)
				message.success(msg)
				return true
			}
			catch (error) {
				message.error(error.message)
			}
			return false
		}
	},


}
export default VendorUpdate